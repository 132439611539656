import styles from "./home.module.scss";
import { Container } from 'react-bootstrap';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';

export default function TempHome() {
    const { t } = useTranslation();

    return (
        <>
            <Head>
                <title>Tigran Tokmajyan</title>
            </Head>
            <section className={`${styles.home} mt-5`}>
                <section className={'home-banner'}>
                </section>
                <section className={styles.about}>
                    <Container>
                        <div className={'d-grid justify-content-between about-container align-items-start'}>
                            <div className={'about-image grey-bg'}>
                                <img src={'./home-about.jpg'} style={{width: "100%", height: "auto"}}/>
                            </div>
                            <div className={'about-text grey-bg'}>
                                <p dangerouslySetInnerHTML={
                                    {__html: t('temp_home_description', {interpolation: {escapeValue: false}})}
                                }>
                                </p>
                            </div>
                        </div>
                    </Container>
                </section>
            </section>
        </>
    );
}
